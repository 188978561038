import { useRef, useState } from "react";
import anh2 from "../../image/anh2.jpg";

import muiten from "../../image/mui ten.png";
import vongquay from "../../image/vongquay.png";

import quayngay from "../../image/nutquay.png";
import "./Wheel.scss";
const Wheel = (props) => {
  const { handleQuay, getResult } = props;
  const [isSpinning, setSpinning] = useState(false);
  const [result, setResult] = useState(null);
  const wheelRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const values = [
    "Voucher 1.000k",
    "Voucher 500k",
    "Voucher 10.000k",
    "Voucher 1.000k",
    "Voucher 500k",
    "Voucher 5.000k",
    "Voucher 20.000k",
    "Voucher 2.000k",
  ];
  const sliceSize = 360 / 8;
  const spinWheel = () => {
    // Reset animation
    if (isSpinning) return;
    const cookieValue = localStorage.getItem("TMWheel");

    if (cookieValue === null) {
      setSpinning(true);
      const fullRots = Math.floor(Math.random() * 5) + 5;
      const targetAngle = 225 * fullRots;
      let initialRotation;
      initialRotation = Math.random() * 360;

      let stopAngle;
      stopAngle = (initialRotation + targetAngle) % 360;
      let stopIndex;
      stopIndex = Math.floor(stopAngle / sliceSize);
      //console.log("check index", stopIndex);
      while (stopIndex === 2 || stopIndex === 6) {
        //console.log("trueeee");
        initialRotation = Math.random() * 360;
        stopAngle = (initialRotation + targetAngle) % 360;
        stopIndex = Math.floor(stopAngle / sliceSize);
      }
      wheelRef.current.style.transition = "none";
      wheelRef.current.style.transform = `rotate(${initialRotation}deg)`;

      setTimeout(() => {
        wheelRef.current.style.transition = "all ease-out 5s";
        wheelRef.current.style.transform = `rotate(${
          initialRotation + targetAngle
        }deg)`;

        // const stopAngle = (initialRotation + targetAngle) % 360;
        // let stopIndex = Math.floor(stopAngle / sliceSize);
        // console.log("check qua: ", stopIndex);
        if (stopIndex < 0) {
          stopIndex = 0;
        } else if (stopIndex > 7) {
          stopIndex = 7;
        }

        setTimeout(() => {
          setResult(values[stopIndex]);
          getResult(values[stopIndex]);
          setIsOpen(true);
          setSpinning(false);
          handleQuay(true);
          localStorage.setItem("TMWheel", "OK");
        }, 6000);
      }, 0);
    } else {
      handleQuay(true);
      getResult("Bạn hết lượt quay !");
    }
  };

  return (
    <>
      <div className="vongquay-container">
        <img src={anh2} alt="anh" className="anh_2" />

        <div className="vongquay">
          <img src={muiten} alt="vong quay" className="arrow" />
          <img
            src={vongquay}
            alt="vong quay"
            ref={wheelRef}
            className="anh_vongquay"
          />

          <div className="btXoay" onClick={() => spinWheel()}>
            <img src={quayngay} alt="btn-quay" className="anh_btn-quay" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Wheel;
